import React, { useState, useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { ScrollToTop, Loader } from "components";
import { AnButton, AnButtonGradient, AnInput, AnLabelForm, AnTextArea } from "components/an";
import { Row, Col, Divider, Space, Alert, Typography, Modal } from "antd";
import moment from "moment";
import { BookingActions, PaymentActions } from "redux/actions";
import Animate from "rc-animate";
import { getWithExpiry } from "services/local-storage";
import BookingSummary from "./components/BookingSummary";
import BookingCreditCardForm from "./components/BookingCreditCardForm";
import BookingAdditionalDataForm from "./components/BookingAdditionalDataForm";

const luhn = require("luhn-validator");

const BookingStepTwo = React.memo((props) => {
    const {
        experience,
        // prevStep,
        // jumpStep,
        nextStep,

        user,
        finalPrice,
        setSavedDate,
        savedDate,
        bookingType,
        createNewBooking,
        loadingClientInfo,
        loadingCreateBooking,
        successCreateBooking,
        fetchClientInfo,
        fetchDocumentTypes,
        documentTypes,
        fetchCardValidations,
        cardValidations,
        setCardNumber,
        cardNumber,
        setCardName,
        cardName,
        setCardDocumentType,
        cardDocumentType,
        setCardDocumentNumber,
        cardDocumentNumber,
        setCardExpiration,
        cardExpiration,
        setCardSecurityCode,
        cardSecurityCode,
        setClientName,
        clientName,
        setClientEmail,
        clientEmail,
        setClientPhone,
        clientPhone,
        setClientAdditionalComments,
        clientAdditionalComments,
        getPaymentToken,
        paymentToken,
        getCreditCardIssuerId,
        creditCardIssuerId,
        resetPayment,
        bookingError,
        setClientWhereToPickup,
        setClientWhereToGo,
        setClientFlightNumber,
        whereToPickup,
        whereToGo,
        flightNumber,
    } = props;

    const history = useHistory();

    const [presubmitError, setPresubmitError] = useState("");
    const [cardNumberValid, setCardNumberValid] = useState(false);
    const [paymentMethodId, setPaymentMethodId] = useState(null);
    const [bookingErrorState, setBookingErrorState] = useState(null);

    useEffect(() => {
        if (!savedDate) {
            history.goBack();
        }
    }, [savedDate, history]);

    useEffect(() => {
        if (successCreateBooking) {
            nextStep();
        }
    }, [successCreateBooking, nextStep]);

    useEffect(() => {
        if (bookingType === 1 || bookingType === 2) {
            fetchDocumentTypes();
            fetchCardValidations();
        }

        if (user && user.role !== "Anonimous") {
            fetchClientInfo(user);
        }

        return () => {
            resetPayment();
        };
    }, [user, bookingType, fetchDocumentTypes, fetchCardValidations, fetchClientInfo, resetPayment]);

    useEffect(() => {
        cardValidations.data.forEach((cardValidation) => {
            if (cardValidation.payment_type_id === "credit_card" || cardValidation.payment_type_id === "debit_card") {
                cardValidation.settings.forEach((cardSetting) => {
                    if (
                        cardNumber.value.match(cardSetting.bin.pattern) &&
                        !cardNumber.value.match(cardSetting.bin.exclusion_pattern)
                    ) {
                        setPaymentMethodId(cardValidation.id);
                        // Card number length
                        if (cardNumber.value.length === cardSetting.card_number.length) {
                            if (cardSetting.card_number.validation === "standard") {
                                // Luhn algorithm
                                if (luhn.validateCard(cardNumber.value).isValid) {
                                    // Set as validated
                                    setCardNumberValid(true);
                                }
                            } else {
                                // Set as validated
                                setCardNumberValid(true);
                            }
                        }
                    }
                });
            }
        });
    }, [cardNumber.value, cardValidations.data]);

    useEffect(() => {
        if (cardNumberValid) {
            getCreditCardIssuerId(cardNumber.value);
        }
    }, [cardNumberValid, cardNumber.value, getCreditCardIssuerId]);

    useEffect(() => {
        if (paymentToken.value) {
            return createNewBooking(experience.id, {
                dateId: savedDate.experienceDateId,
                userPromotorNic: getWithExpiry("promoter-nic") || "",
                peopleQuantity: Math.ceil(savedDate.peopleQuantity / experience.peopleQuantity),
                bookingType,
                clientInfo: {
                    clientName,
                    clientEmail,
                    clientPhone,
                    clientAdditionalComments,
                    whereToPickup,
                    whereToGo,
                    flightNumber,
                },
                paymentData: {
                    paymentToken: paymentToken.value,
                    paymentMethodId,
                    creditCardIssuerId: creditCardIssuerId.value,
                },
            });
        }
    }, [paymentToken, createNewBooking, getWithExpiry]);

    useEffect(() => {
        if (bookingError) {
            Modal.error({
                title: "Error en la reserva",
                content: <Typography.Text>{bookingError}</Typography.Text>,
                onOk() {},
                centered: true,
                closable: false,
                okText: "Entendido",
                transitionName: "zoom",
                okButtonProps: {
                    shape: "round",
                },
            });
        }
    }, [bookingError]);

    // Handlers
    const handlePrevStep = useCallback(() => {
        setSavedDate(null);
    }, [setSavedDate]);

    const handleConfirmBooking = () => {
        setPresubmitError("");

        // Check if booking requieres payment
        // to create payment token
        if (bookingType === 1 || bookingType === 2) {
            if (!cardNumber.value) {
                return setPresubmitError("El número de tarjeta es requerido");
            }

            if (!cardNumber.validLuhn) {
                return setPresubmitError("El número de tarjeta no es válido");
            }

            if (!cardName) {
                return setPresubmitError("El titular de la tarjeta es requerido");
            }

            if (!cardDocumentNumber) {
                return setPresubmitError("El número de documento es requerido");
            }

            if (!cardExpiration.value) {
                return setPresubmitError("La fecha de expiración es requerida");
            }

            if (!cardExpiration.validCardExpiration) {
                return setPresubmitError("La fecha de expiración no es válida");
            }

            if (!cardSecurityCode.value) {
                return setPresubmitError("El código de seguridad es requerido");
            }

            if (!cardSecurityCode.validCardSecurityCode) {
                return setPresubmitError("El código de seguridad no es válido");
            }

            if (!clientName) {
                return setPresubmitError("El nombre de contacto es requerido");
            }

            if (!clientPhone) {
                return setPresubmitError("El número de teléfono de contacto es requerido");
            }

            if (!clientEmail) {
                return setPresubmitError("El email de contacto es requerido");
            }

            getPaymentToken({
                cardNumber: cardNumber,
                cardName: cardName,
                cardDocumentType: cardDocumentType.id,
                cardDocumentNumber: cardDocumentNumber,
                securityCode: cardSecurityCode.value,
                expirationMonth: Number(cardExpiration.value.split("/")[0]),
                expirationYear: Number(cardExpiration.value.split("/")[1]),
            });
        } else {
            if (!clientName) {
                return setPresubmitError("El nombre de contacto es requerido");
            }

            if (!clientPhone) {
                return setPresubmitError("El número de teléfono de contacto es requerido");
            }

            if (!clientEmail) {
                return setPresubmitError("El email de contacto es requerido");
            }

            // If no payment required we just
            // create the booking
            createNewBooking(experience.id, {
                dateId: savedDate.experienceDateId,
                userPromotorNic: getWithExpiry("promoter-nic") || "",
                peopleQuantity: Math.ceil(savedDate.peopleQuantity / experience.peopleQuantity),
                bookingType,
                clientInfo: {
                    clientName,
                    clientEmail,
                    clientPhone,
                    clientAdditionalComments,
                    whereToPickup,
                    whereToGo,
                    flightNumber,
                },
            });
        }
    };

    return (
        <>
            <ScrollToTop />

            {documentTypes.error || cardValidations.error ? (
                <div style={{ padding: 15 }}>
                    <Alert
                        message={"Ha ocurrido un error!"}
                        description={"Ha ocurrido un error al intentar iniciar el proceso de reserva"}
                        type={"error"}
                        showIcon
                    />
                </div>
            ) : (
                <>
                    {((bookingType === 1 || bookingType === 2) && cardValidations.loading) || loadingClientInfo ? (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flex: 1,
                                height: "100%",
                            }}
                        >
                            <Loader loading={true} />
                        </div>
                    ) : (
                        <div className="booking-summary-before-confirmation">
                            <div className="main-container">
                                <>
                                    <Typography.Title
                                        style={{
                                            fontSize: 18,
                                            marginBottom: "30px",
                                            background: "#fafafa",
                                            padding: "10px",
                                            borderRadius: "10px",
                                        }}
                                    >
                                        Datos Reserva
                                    </Typography.Title>

                                    <BookingSummary
                                        title={experience?.title}
                                        day={moment.utc(savedDate?.dayAndHour).format("DD-MM-yyyy")}
                                        hour={moment.utc(savedDate?.dayAndHour).format("HH:mm")}
                                        bookingType={bookingType}
                                        capacity={`${experience?.peopleQuantity} Personas`}
                                        price={`$${experience.price}`}
                                        bookingsQuantity={Math.ceil(
                                            savedDate?.peopleQuantity / experience?.peopleQuantity
                                        )}
                                        bookingPercentage={experience?.bookingPercentage}
                                        total={finalPrice}
                                    />
                                </>

                                {(bookingType === 1 || bookingType === 2) && (
                                    <>
                                        <Divider />

                                        <Typography.Title
                                            style={{
                                                fontSize: 18,
                                                marginBottom: "30px",
                                                background: "#fafafa",
                                                padding: "10px",
                                                borderRadius: "10px",
                                            }}
                                        >
                                            Datos Tarjeta
                                        </Typography.Title>

                                        <Alert
                                            message={"Atención"}
                                            description={
                                                bookingType === 1
                                                    ? `Se deberá realizar el pago de la experiencia completa por un monto de $${finalPrice} para confirmar la reserva`
                                                    : `Se deberá realizar el pago parcial de la experiencia por un monto de $${(
                                                          finalPrice * experience?.bookingPercentage
                                                      ).toFixed(
                                                          2
                                                      )} para confirmar la reserva, el importe restante de $${(
                                                          finalPrice -
                                                          finalPrice * experience?.bookingPercentage
                                                      ).toFixed(2)} deberá ser abonado en el lugar de la experiencia`
                                            }
                                            type={"warning"}
                                            showIcon
                                        />

                                        <div
                                            style={{
                                                marginTop: 30,
                                            }}
                                        >
                                            <BookingCreditCardForm
                                                cardNumberPlaceholder=""
                                                cardNumber={cardNumber.formatted}
                                                setCardNumber={(v) => {
                                                    setCardNumber(v.target.value);
                                                }}
                                                validCardNumber={cardNumber.validCardNumber}
                                                cardNamePlaceholder=""
                                                cardName={cardName}
                                                setCardName={(v) => {
                                                    setCardName(v.target.value.toUpperCase());
                                                }}
                                                cardDocumentTypePlaceholder=""
                                                cardDocumentTypeOptions={documentTypes.data}
                                                cardDocumentType={{ id: cardDocumentType }}
                                                setCardDocumentType={(value) => {
                                                    setCardDocumentType(value.id);
                                                }}
                                                cardDocumentNumberPlaceholder=""
                                                cardDocumentNumber={cardDocumentNumber}
                                                setCardDocumentNumber={(v) => {
                                                    setCardDocumentNumber(v.target.value);
                                                }}
                                                cardExpirationPlaceholder="MM/AAAA"
                                                cardExpiration={cardExpiration.value}
                                                setCardExpiration={(v) => {
                                                    setCardExpiration(v.target.value);
                                                }}
                                                validCardExpiration={cardExpiration.validCardExpiration}
                                                cardSecurityCodePlaceholder=""
                                                cardSecurityCode={cardSecurityCode.value}
                                                setCardSecurityCode={(v) => {
                                                    setCardSecurityCode(v.target.value);
                                                }}
                                            />
                                        </div>
                                    </>
                                )}

                                <>
                                    <Divider />

                                    <Typography.Title
                                        style={{
                                            fontSize: 18,
                                            marginBottom: "30px",
                                            background: "#fafafa",
                                            padding: "10px",
                                            borderRadius: "10px",
                                        }}
                                    >
                                        Datos de Contacto
                                    </Typography.Title>

                                    <BookingAdditionalDataForm
                                        clientName={clientName}
                                        setClientName={(e) => {
                                            setClientName(e.target.value);
                                        }}
                                        clientEmail={clientEmail}
                                        setClientEmail={(e) => {
                                            setClientEmail(e.target.value);
                                        }}
                                        clientPhone={clientPhone}
                                        setClientPhone={(e) => {
                                            setClientPhone(e.target.value);
                                        }}
                                        clientAdditionalComments={clientAdditionalComments}
                                        setClientAdditionalComments={(e) => {
                                            setClientAdditionalComments(e.target.value);
                                        }}
                                    />
                                </>

                                {(experience.bookingRequires.includes("requireWhereToPickup") ||
                                    experience.bookingRequires.includes("requireWhereToGo") ||
                                    experience.bookingRequires.includes("requireFlightNumber")) && (
                                    <>
                                        <Divider />

                                        <Typography.Title
                                            style={{
                                                fontSize: 18,
                                                marginBottom: "30px",
                                                background: "#fafafa",
                                                padding: "10px",
                                                borderRadius: "10px",
                                            }}
                                        >
                                            Datos adicionales de reserva
                                        </Typography.Title>

                                        <Row gutter={[0, 15]}>
                                            {experience.bookingRequires.includes("requireWhereToPickup") && (
                                                <Col span={24}>
                                                    <AnLabelForm label="¿Dónde te recogemos? / Where do you want to be collected? *" />

                                                    <AnTextArea
                                                        className="an-textarea round"
                                                        autoSize={{
                                                            minRows: 2,
                                                        }}
                                                        placeholder="Ej: Corrientes y Uruguay"
                                                        value={whereToPickup}
                                                        onChange={(e) => setClientWhereToPickup(e.target.value)}
                                                    />
                                                </Col>
                                            )}

                                            {experience.bookingRequires.includes("requireWhereToGo") && (
                                                <Col span={24}>
                                                    <AnLabelForm label="¿A dónde te llevamos? / Where do you want to be dropped off? *" />

                                                    <AnTextArea
                                                        className="an-textarea round"
                                                        autoSize={{
                                                            minRows: 2,
                                                        }}
                                                        placeholder="Ej: Aeroparque"
                                                        value={whereToGo}
                                                        onChange={(e) => setClientWhereToGo(e.target.value)}
                                                    />
                                                </Col>
                                            )}

                                            {experience.bookingRequires.includes("requireFlightNumber") && (
                                                <Col span={24}>
                                                    <AnLabelForm label="N° de vuelo / Flight number *" />

                                                    <AnInput
                                                        placeholder=""
                                                        value={flightNumber}
                                                        onChange={(e) => setClientFlightNumber(e.target.value)}
                                                    />
                                                </Col>
                                            )}
                                        </Row>
                                    </>
                                )}

                                <Animate transitionName="fade" transitionAppear>
                                    {bookingType === 3 && (
                                        <Alert
                                            message="Aviso:"
                                            description="Esta reserva se deberá abonar en el lugar de la experiencia"
                                            type="warning"
                                            showIcon
                                            style={{
                                                marginTop: 15,
                                                borderRadius: 10,
                                            }}
                                        />
                                    )}
                                </Animate>

                                <Divider />

                                {presubmitError ? (
                                    <>
                                        <Alert
                                            message="Atención"
                                            description={presubmitError}
                                            type="error"
                                            showIcon
                                            style={{
                                                borderRadius: 10,
                                            }}
                                        />

                                        <Divider />
                                    </>
                                ) : null}

                                <Row align="center" justify="end">
                                    <Col>
                                        <Space>
                                            <AnButton
                                                ghost
                                                onClick={handlePrevStep}
                                                type="primary"
                                                shape="round"
                                                size="large"
                                                disabled={paymentToken.loading || loadingCreateBooking}
                                            >
                                                Volver
                                            </AnButton>

                                            <AnButtonGradient
                                                type="primary"
                                                shape="round"
                                                size="large"
                                                loading={paymentToken.loading || loadingCreateBooking}
                                                onClick={handleConfirmBooking}
                                            >
                                                Confirmar
                                            </AnButtonGradient>
                                        </Space>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    )}
                </>
            )}
        </>
    );
});

const mapStateToProps = (state) => ({
    user: state.context.user,
    bookingType: state.booking.bookingType,
    savedDate: state.booking.savedDate,
    finalPrice: state.booking.finalPrice,

    loadingClientInfo: state.booking.loadingClientInfo,
    loadingCreateBooking: state.booking.loadingCreateBooking,
    successCreateBooking: state.booking.successCreateBooking,

    documentTypes: state.payment.documentTypes,
    cardValidations: state.payment.cardValidations,
    cardNumber: state.payment.cardNumber,
    cardName: state.payment.cardName,
    cardDocumentType: state.payment.cardDocumentType,
    cardDocumentNumber: state.payment.cardDocumentNumber,
    cardExpiration: state.payment.cardExpiration,
    cardSecurityCode: state.payment.cardSecurityCode,
    clientName: state.booking.clientName,
    clientEmail: state.booking.clientEmail,
    clientPhone: state.booking.clientPhone,
    clientAdditionalComments: state.booking.clientAdditionalComments,
    whereToPickup: state.booking.whereToPickup,
    whereToGo: state.booking.whereToGo,
    flightNumber: state.booking.flightNumber,
    creditCardIssuerId: state.payment.creditCardIssuerId,
    paymentToken: state.payment.paymentToken,
    bookingError: state.booking.error,
});

const mapDispatchToProps = (dispatch) => ({
    fetchClientInfo: (user) => dispatch(BookingActions.getClientInfo(user)),
    fetchDocumentTypes: () => dispatch(PaymentActions.fetchDocumentTypes()),
    fetchCardValidations: () => dispatch(PaymentActions.fetchCardValidations()),
    setCardNumber: (cardNumber) => dispatch(PaymentActions.setCardNumber(cardNumber)),
    setCardName: (cardName) => dispatch(PaymentActions.setCardName(cardName)),
    setCardDocumentType: (cardDocumentType) => dispatch(PaymentActions.setCardDocumentType(cardDocumentType)),
    setCardDocumentNumber: (cardDocumentNumber) => dispatch(PaymentActions.setCardDocumentNumber(cardDocumentNumber)),
    setCardExpiration: (cardExpiration) => dispatch(PaymentActions.setCardExpiration(cardExpiration)),
    setCardSecurityCode: (cardSecurityCode) => dispatch(PaymentActions.setCardSecurityCode(cardSecurityCode)),
    createNewBooking: (experienceId, bookingData) =>
        dispatch(BookingActions.createNewBooking(experienceId, bookingData)),
    setSavedDate: (savedDate) => dispatch(BookingActions.setSavedDate(savedDate)),
    setClientName: (name) => dispatch(BookingActions.setClientName(name)),
    setClientEmail: (email) => dispatch(BookingActions.setClientEmail(email)),
    setClientPhone: (phone) => dispatch(BookingActions.setClientPhone(phone)),
    setClientAdditionalComments: (comments) => dispatch(BookingActions.setClientAdditionalComments(comments)),
    setClientWhereToPickup: (text) => dispatch(BookingActions.setClientWhereToPickup(text)),
    setClientWhereToGo: (text) => dispatch(BookingActions.setClientWhereToGo(text)),
    setClientFlightNumber: (text) => dispatch(BookingActions.setClientFlightNumber(text)),
    getPaymentToken: (params) => dispatch(PaymentActions.getPaymentToken(params)),
    getCreditCardIssuerId: (bin) => dispatch(PaymentActions.getCreditCardIssuerId(bin)),
    resetPayment: () => dispatch(PaymentActions.reset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(BookingStepTwo);
